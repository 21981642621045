<template>
    <div>
        <scheduleTeamList ></scheduleTeamList>
    </div>
</template>
<script>

import scheduleTeamList from '@/components/olympics/schedule/schedule_team/list.vue';

export default {
    components: {
        scheduleTeamList
    },
    data () {
        let self = (window.x = this)
        return {
            // sport_id: 17,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>